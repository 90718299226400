export const HelpJson = {
    THINGS_TO_REMEMBER: {
        headers: ['Remember', 'Value'],
        headerKeys: ['desc', 'grade'],
        detail: [
            {
                desc: 'Use Space to differentiate individual attributes.',
                grade: 'RD 1.00 F SI1',
            },
            {
                desc: 'Use Comma for specifying multiple values of a particular parameter.',
                grade: 'PS, OV 1.00 F SI1, SI2',
            },
            {
                desc: 'Use \'#\' or \'-\' minus sign to define a range for any values.',
                grade: 'RD 0.50-0.70 D-F VVS1-VS2',
            },
            {
                desc: 'Search by Packet / Certificate Number:',
                grade: '862041, 950446, 2204869191',
            },
            {
                desc: 'Search by Cut, Polish and Symmetry Combination :',
                grade: '3EX, 3X, XXX, 2EX, 2X, XX, 3VG+',
            },
            {
                desc: 'For Colors',
                grade: 'D+, E+, F+, G+, H+, I+, D-E, D-F, D-G, D-H, D-I.',
            },
            {
                desc: 'For Clarity',
                grade: 'IF+, VVS1+, VVS2+, VVS, VS1+, VS2+, VS, SI1+, SI2+, SI3+, SI, I1+, I2+, I3+.',
            },
        ],
    },
    SHAPE: {
        headers: ['Shape', 'Shortcuts'],
        headerKeys: ['shape', 'grade'],
        detail: [
            {
                shape: 'ROUND',
                grade: 'RO, RBC, BR, RD, RND, B, RB, ROUND',
            },
            {
                shape: 'PRINCESS',
                grade: 'PRN, PR, PRIN, PN, PC, MDSQB, SMB, PRINCESS',
            },
            {
                shape: 'PEAR',
                grade: 'PEAR, PS, PSH, PB, PM, PEAR',
            },
            {
                shape: 'HEART',
                grade: 'HEART, HS, HT, MHRC, HEART',
            },
            {
                shape: 'OVAL',
                grade: 'OVAL, OV, OMB, OVAL',
            },
            {
                shape: 'MARQUISE',
                grade: 'MARQUISE, MQB, MQ, MARQUISE',
            },
            {
                shape: 'EMERALD',
                grade: 'EMERALD, EM, EC, EMERALD',
            },
            {
                shape: 'SQ.EMERALD',
                grade: 'SQE, SQEM, SX',
            },
            {
                shape: 'RADIANT',
                grade: 'RADIANT, RADIANT',
            },
            {
                shape: 'CUSHION',
                grade: 'CUSHION, CUBR, CUMOD, CUSHION',
            },
        ],
    },
    CLARITY: {
        headers: ['Clarity', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                grade: 'For IF to VS2',
                val: 'VS+, IF-VS2',
            },
            {
                grade: 'VVS',
                val: 'VVS1, VVS2, VVS',
            },
            {
                grade: 'VS',
                val: 'VS1, VS2, VS',
            },
            {
                grade: 'SI',
                val: 'SI1, SI2, SI3, SI',
            },
        ],
    },
    CUT_POLISH_SYMMETRY: {
        headers: ['Cut, Polish, Symmetry', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                desc: 'EXCELLENT',
                grade: 'EX EXC X VG+ GD+',
            },
            {
                desc: 'VERY GOOD',
                grade: 'VG VG+ GD+',
            },
            {
                desc: 'GOOD',
                grade: 'GD GD+',
            },
            {
                desc: 'FAIR',
                grade: 'FR',
            },
        ],
    },
    FLUORESCENCE: {
        headers: ['Fluoresence', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                desc: 'NONE',
                grade: 'N, NN',
            },
            {
                desc: 'FAINT',
                grade: 'F FNT',
            },
            {
                desc: 'VERY SLIGHT',
                grade: 'VSL',
            },
            {
                desc: 'MEDIUM',
                grade: 'M MED',
            },
            {
                desc: 'SLIGHT',
                grade: 'SL, SLIGHT',
            },
            {
                desc: 'STRONG',
                grade: 'S ST',
            },
            {
                desc: 'VERY STRONG',
                grade: 'VST',
            },
        ],
    },
    LOCATION: {
        headers: ['Location', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                Location: 'MUMBAI',
                desc: 'MUMBAI BOMBAY MUM BOM INDIA IND',
            },
            {
                Location: 'HONGKONG',
                desc: 'HONGKONG HK',
            },
            {
                Location: 'NEWYORK',
                desc: 'NEWYORK NY US USA',
            },
            {
                Location: 'CHINA',
                desc: 'CHINA',
            },
            {
                Location: 'SHOW',
                desc: 'CHINA',
            },
        ],
    },
    CARAT_RAP_PRICE: {
        headers: ['Carat, Rap Disc & Price / Carat.', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                desc: 'For Carat Weight valid range is between 0 – 20',
                grade: 'RD 1-1.19 D-F IF-VVS2',
            },
            {
                desc: 'For Rap Discount valid range is between 0 – 99',
                grade: 'RD 1-1.19 30-39',
            },
            {
                desc: 'For Price per Carat valid range is between 100 – 999999999',
                grade: 'RD 1-1.19 5000-7000',
            },
        ],
    },
    INCLUSION: {
        headers: ['Inclusion', 'Value'],
        headerKeys: ['grade', 'val'],
        detail: [
            {
                grade: 'Black Table Inclusion',
                desc: 'BT0,BT1,BT2,BT3',
            },
            {
                grade: 'Black Crown Inclusion',
                desc: 'BC0,BC1,BC2,BC3',
            },
            {
                grade: 'White Table Inclusion',
                desc: 'WT0,WT1,WT2,WT3',
            },
            {
                grade: 'White Crown Inclusion',
                desc: 'WC0,WC1,WC2,WC3',
            },
            {
                grade: 'Milky',
                desc: 'M0,M1,M2,M3',
            },
            {
                grade: 'Open Table',
                desc: 'TO0,TO1,TO2,TO3',
            },
            {
                grade: 'Open Crown',
                desc: 'CO0,CO1,CO2,CO3',
            },
            {
                grade: 'Open Pavilion',
                desc: 'PO0,PO1,PO2,PO3',
            },
            {
                grade: 'Eye Clean',
                desc: 'E1,E2,E3',
            },
        ],
    },
};
