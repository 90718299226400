import React from "react";
import HelpComponent from "../components/Help/Help";
import SEO from "../components/SEO";
import {graphql} from "gatsby";

const Help = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/> <HelpComponent {...props}/></React.Fragment>;
export default Help;
export const query = graphql`
  query HelpQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "help-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;