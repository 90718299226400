import React, {Component} from 'react';
import FrontFooter from '../FrontStatic/common/Front-Footer';
import FrontWrapper from "../FrontStatic/common/FrontWrapper";
import {HelpJson} from './HelpJson';

class Help extends Component {
    render() {
        let remeberThing = HelpJson.THINGS_TO_REMEMBER;
        let shapes = HelpJson.SHAPE;
        let clarity = HelpJson.CLARITY;
        let carpolishymmetry = HelpJson.CUT_POLISH_SYMMETRY;
        let Fluorescence = HelpJson.FLUORESCENCE;
        let location = HelpJson.LOCATION;
        let caraterapprice = HelpJson.CARAT_RAP_PRICE;
        let inclusion = HelpJson.INCLUSION;
        return (
            <FrontWrapper location={this.props.location}>
                <div className="page-main page-current front-grading">
                    <div className="scroll-content-wrapper">
                        <div
                            className="page-toload legacy-page"
                            data-bodyclassname="legacy"
                        >
                            <main className="page-content" role="main">
                                <section className="section-container our-help our-service-bn contact-banner">
                                    <div style={{margin: 'auto'}} className="mb-100 mb-xs-40">
                                        <h2 className="show-text head padd-max js-type-animation-alt">
                                            Help with innovative search method
                                        </h2>
                                    </div>
                                    <div className="diamond-detail-content all-content-block grading-main-block">
                                        <div className="width-full">
                                            <div className="diamond-education static-page">
                                                <div className="mb-50 d-flex d-xs-block">
                                                    <div className="width-50 pr-30 width-xs-full">
                                                        <div className="diamond-education-img mb-25">
                                                            <iframe title="Video1" className="width-full height-100" src="https://www.youtube.com/embed/9a2wM1RenlE"></iframe>
                                                          {/*  <img className="width-full"
                                                                 src={require('../../assets/img/concierge-services.png')}
                                                                 alt=""/>*/}
                                                            {/*<div className="video-play play-service-video">
                                                                <img src={require('../../assets/svg/play-button.svg')}
                                                                     alt=""/>
                                                            </div>*/}
                                                        </div>
                                                    </div>
                                                    <div className="width-50 width-xs-full help-content">
                                                        <div className="diamond-education-dec">
                                                            <h2 className="mb-15">How to Search from Innovative
                                                                Search?</h2>
                                                            <p className="mb-15">
                                                                Innovative Search is an easy to use text based search
                                                                engine
                                                                for
                                                                Diamonds. Lets move ahead from the old method of
                                                                selecting
                                                                individual
                                                                values and achieve whats required by simply writing the
                                                                same
                                                                in plain
                                                                text.
                                                            </p>
                                                            <p>For Example,</p>
                                                            <h4>D VVS1 1.00 ROUND GIA 3EX NONE</h4>
                                                            <p>For More Detail, Please read below Instructions.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex d-xs-block grading-content">
                                                    <div className=" pr-30 mb-xs-20 mb-50 width-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body  help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Things to Remember</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {remeberThing.headers.map(data => (
                                                                            <td key={data}>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {remeberThing.detail.map((data) => (
                                                                        <tr>
                                                                            <td>{data.desc}</td>
                                                                            <td>{data.grade}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-50 mb-xs-20 width-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Shape</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {shapes.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {shapes.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.shape}</td>
                                                                            <td>{data.grade}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex d-xs-block">
                                                    <div className="pr-30 mb-xs-20 width-50 mb-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Size</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Size</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>0.30 to 10 Carats</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-50 mb-xs-20 width-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block ">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Clarity</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {clarity.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {clarity.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.grade}</td>
                                                                            <td>{data.val}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex d-xs-block">
                                                    <div className="pr-30 width-50 mb-xs-20 mb-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Cut, Polish, Symmetry</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {carpolishymmetry.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {carpolishymmetry.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.desc}</td>
                                                                            <td>{data.grade}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-50 mb-xs-20 width-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Fluorescence</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {Fluorescence.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {Fluorescence.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.desc}</td>
                                                                            <td>{data.grade}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex d-xs-block">
                                                    <div className="pr-30 width-50 mb-xs-20 mb-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Location</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {location.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {location.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.Location}</td>
                                                                            <td>{data.desc}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-50 mb-xs-20 width-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Carat, Rap Disc & Price / Carat.
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        {caraterapprice.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {caraterapprice.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.desc}</td>
                                                                            <td>{data.grade}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex d-xs-block">
                                                    <div className="pr-30 width-50 mb-xs-20 mb-50 width-xs-full">
                                                        <div className="card-table grading-table grading-block">
                                                            <div className="card-table-body help-card-table-body">
                                                                <table>
                                                                    <tbody>
                                                                    <tr className="thead-BG">
                                                                        <th colSpan="2">Inclusion</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {inclusion.headers.map(data => (
                                                                            <td>
                                                                                <span>{data}</span>
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    {inclusion.detail.map((data, index) => (
                                                                        <tr>
                                                                            <td>{data.grade}</td>
                                                                            <td>{data.desc}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex d-xs-block width-xs-full mb-xs-0 pb-xs-20">
                                                    <div className="width-full">
                                                        <h2 className="mb-30">Innovative Search Demonstration</h2>
                                                        <iframe title="Video2" className="height-700 width-100" src="https://www.youtube.com/embed/w05cN-TqG7c?autoplay=0"></iframe>
                                                        {/*<div*/}
                                                        {/*    className="diamond-education-img height-700 mb-25 overlap-bg">*/}
                                                        {/*    <img*/}
                                                        {/*        className="width-full"*/}
                                                        {/*        src={require('../../assets/img/Innovative-Search-Demonstration.png')}*/}
                                                        {/*        alt=""*/}
                                                        {/*    />*/}
                                                        {/*    <div className="video-play play-service-video">*/}
                                                        {/*        <img src={require('../../assets/svg/play-button.svg')}*/}
                                                        {/*             alt=""/>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <FrontFooter/>
                            </main>
                        </div>
                    </div>
                    
                </div>
                {/*<SEO url={this.props.location.pathname.split('/')[1]} />*/}
  
            </FrontWrapper>
        );
    }
}

export default Help;
